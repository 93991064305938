<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="关键词" span="6">
          <vxe-input v-model="listQuery.param.keyWord" placeholder="请输入关键词" clearable />
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <template slot="createUser" slot-scope="{row}">
        <CustomPic v-show="row.createUserAvatar" :src="row.createUserAvatar" :src-list="[row.createUserAvatar]" />
        <br v-show="row.createUserAvatar">
        {{ row.createUserName }}
      </template>
      <template slot="url" slot-scope="{row}">
        <a v-if="row.url" :href="row.url" target="_blank">
          <el-link type="primary" :underline="false">{{ row.url }}</el-link>
        </a>
      </template>
      <CustomPic slot="photos" slot-scope="{row}" :src="getPhotos(row.photos, 0)" :src-list="getPhotos(row.photos)" />
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'AuditNeighborhoodCheckButton'" size="small" type="text" @click="handleDetail(row.id)">查看</el-button>
        <el-button v-permission="'AuditNeighborhoodPassButton'" size="small" type="text" @click="handleAudit(row.id, 1)">通过</el-button>
        <el-button v-permission="'AuditNeighborhoodRejectButton'" size="small" type="text" @click="handleAudit(row.id, 2)">拒绝</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from '@/views/Pages/neighborhood/neighborhood/components/form'
export default {
  name: 'Neighborhood',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      createTypeArray: ['小程序用户', '系统用户'],
      listQuery: {
        param: {
          hasTenant: true,
          status: 0
        }
      },
      tableColumns: [
        {
          title: '发布人类型',
          formatter: (row, column) => {
            switch (row.createType) {
              case 0:
                return '小程序用户'
              case 1:
                return '系统用户'
              default:
                return ''
            }
          },
          minWidth: 100
        },
        {
          title: '发布人',
          slot: 'createUser',
          minWidth: 100
        },
        {
          title: '图片',
          slot: 'photos'
        },
        {
          prop: 'address',
          title: '地址',
          minWidth: 150
        },
        {
          title: '话题',
          formatter: (row, column) => {
            return row.topics ? row.topics.split(',').join(' | ') : ''
          },
          minWidth: 200
        },
        {
          title: 'URL',
          slot: 'url',
          minWidth: 200
        },
        {
          prop: 'likeNumber',
          title: '点赞数',
          minWidth: 100
        },
        {
          prop: 'commentsNumber',
          title: '评论数',
          minWidth: 100
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 150
        }
      ]
    }
  },
  methods: {
    ...mapActions(['neighborhoodPublicPageList', 'auditAudit']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.neighborhoodPublicPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleDetail(id) {
      this.$refs.Form.handleDetail(id)
    },
    handleAudit(mainId, status) {
      this.$confirm(`确认${status === 1 ? '通过' : '拒绝'}吗？`, '', {
        type: 'warning',
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        showInput: status === 2
      }).then(data => {
        let reason = ''
        if (typeof data === 'object' && 'value' in data) {
          reason = data.value
        }
        this.auditAudit({ type: 3, reason, mainId, status }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('操作成功')
          this.handleSearch(true)
        }).catch(() => {})
      }).catch(() => {})
    },
    getPhotos(photos, index) {
      let photosArray = []
      if (photos) {
        photosArray = photos.split(',')
      }

      if (index === 0) {
        return photosArray.length ? photosArray[0] : ''
      } else {
        return photosArray
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
